/**
 * TRACKING GTM
 * @author alexandre Flatrès
 * par artwaï pour Groupe Bâtisseurs d'avenir
 *
 * JS sur le tracking GTM
 */
(function () {
	// Tracking sur toutes les pages
	window.onload = function () {
		dataTracking.env_channel = window.getComputedStyle(
			document.querySelector('html')
		)['z-index'];
		if (dataTracking.env_channel != '') {
			if (dataTracking.env_channel == '1') {
				dataTracking.env_channel = 'mobile';
			} else if (dataTracking.env_channel == '2') {
				dataTracking.env_channel = 'tablet';
			} else if (dataTracking.env_channel == '3') {
				dataTracking.env_channel = 'desktop';
			} else if (dataTracking.env_channel == '4') {
				dataTracking.env_channel = 'wide';
			}
		}

		window.dataLayer.push({
			event: 'datalayer-loaded',
			page_type: dataTracking.pageType,
			env_channel: dataTracking.env_channel,
			page_cat: dataTracking.page_cat,
			program_id: dataTracking.program_id,
			promotion_name: dataTracking.promotion_name,
			program_category: dataTracking.program_category,
			program_price_from: dataTracking.program_price_from,
			program_type: dataTracking.program_type,
			program_eligibility: dataTracking.program_eligibility,
			program_localisation: dataTracking.program_localisation,
			'connected-user': dataTracking.is_connected ? '1' : '0',
		});
	};

	// Tracking à chaque validation d'un formulaire WPCF7
	document.addEventListener(
		'wpcf7mailsent',
		function (event) {
			// GET id_source Unlatch from contact form
			var formId = event.detail.contactFormId;
			var currentForm = dataTracking.api_leads.find(
				(o) => o.formulaire === formId
			);
			if (currentForm !== undefined) {
				formId =
					currentForm[dataTracking.current_structure.id_source_reference];
			}

			// GET email from inputs array with name your-email
			var contactEmail = event.detail.inputs.find(
				(o) => o.name === 'your-email'
			).value;
			if (contactEmail == '') {
				contactEmail = 'Email non trouvé';
			}

			var formDestinataire = event.detail.inputs.find(
				(o) => o.name === 'to'
			);
            if (formDestinataire == undefined) {
                formDestinataire = '';
            } else {
                formDestinataire = formDestinataire.value;
            }

            

			// Si on est sur un formulaire qui n'est pas une Offre d'emploi ET un Plan ET un document à télécharger
			if (
				event.detail.contactFormId != dataTracking.contact_plan_id &&
				event.detail.contactFormId != dataTracking.contact_document_id &&
				event.detail.contactFormId != dataTracking.contact_offer_id
			) {
				var contactFormTitle = event.target.querySelector(
					'.description-form strong'
				).innerText;
				if (contactFormTitle == '') {
					contactFormTitle = 'Titre du formulaire non trouvé';
				}

				// Si on est sur un programme ou un terrain
				if (
					dataTracking.pageType == 'program' ||
					dataTracking.pageType == 'land'
				) {
					var lotReference = event.target.querySelector(
						'input[name*="lot-reference"]'
					).value;
					var datasArr = {
						event: '',
						form_id: formId,
						contact_type: contactFormTitle,
						user_mail: contactEmail,
						destinataire: formDestinataire,
						contact_currency: 'EUR',
						product: [
							{
								program_name: dataTracking.promotion_name,
								apartment_quantity: '1',
								program_localisation: dataTracking.program_localisation,
							},
						],
					};

					if (lotReference != '') {
						// Si on est sur une demande de contact LOT
						var lotPrice = event.target.querySelector(
							'input[name*="lot-price"]'
						).value;
						datasArr.event = 'apartment-contact-form-success';
						datasArr.contact_price_from = lotPrice;
						datasArr.product[0].apartment_ref = event.target.querySelector(
							'input[name*="lot-id"]'
						).value;
						datasArr.product[0].apartment_name =
							lotReference != '' ? lotReference.split(': ')[1] : '';
						datasArr.product[0].apartment_floor = event.target.querySelector(
							'input[name*="lot-floor"]'
						).value;
						datasArr.product[0].apartment_price_from = lotPrice;
						datasArr.product[0].apartment_exposition =
							event.target.querySelector('input[name*="lot-exposition"]').value;
					} else {
						// Si on est sur une demande de contact OPERATION
						datasArr.event = 'program-contact-form-success';
						datasArr.contact_price_from = dataTracking.program_price_from;
						datasArr.product[0].program_id = dataTracking.program_id;
						datasArr.product[0].program_type = dataTracking.program_type;
						datasArr.product[0].program_price_from =
							dataTracking.program_price_from;
						datasArr.product[0].program_eligibility =
							dataTracking.program_eligibility;
					}

					window.dataLayer.push(datasArr);
				} else {
					window.dataLayer.push({
						event: 'contact_form_success',
						form_id: formId,
						contact_type: contactFormTitle,
						user_mail: contactEmail,
						destinataire: formDestinataire,
					});
				}
			}

			// Si on est sur un formulaire de téléchargement de document
			if (event.detail.contactFormId == dataTracking.contact_document_id) {
				let docname = event.target.querySelector(
					'input[name*="your-document"]'
				).value;
				let doctype = event.target
					.querySelector('.description-form strong')
					.innerText.trim()
					.toLowerCase();
				doctype = doctype.replace(' par email', '');
				doctype = doctype.replace('télécharger le ', '');
				doctype = doctype.replace('télécharger la ', '');

				window.dataLayer.push({
					event: 'doc_download',
					form_id: formId,
					doc_name: docname,
					doc_type: doctype,
					program_name: dataTracking.promotion_name,
					user_mail: contactEmail,
					destinataire: formDestinataire,
				});
			} else if (event.detail.contactFormId == dataTracking.contact_plan_id) {
				// Si on est sur un formulaire de téléchargement de document
				let docname = event.target.querySelector(
					'input[name*="your-plan"]'
				).value;
				let doctype = 'plan'

				window.dataLayer.push({
					event: 'plan_download',
					form_id: formId,
					doc_name: docname,
					doc_type: doctype,
					program_name: dataTracking.promotion_name,
					user_mail: contactEmail,
					destinataire: formDestinataire,
				});
			}

			// Si on est sur un formulaire d'offre d'emploi
			if (event.detail.contactFormId == dataTracking.contact_offer_id) {
				window.dataLayer.push({
					event: 'job-form-success',
					form_id: formId,
				});
			}
		},
		false
	);


	// Tracking a chaque recherche simple 
	document.addEventListener(
		'click',
		function (event) {
			const search_button = document.querySelector('form.searchform--main .searchform__button');
			if (search_button) {
				let form = search_button.closest('form');
				let typology = form.querySelector('select[name="typology[]"]');
				let typeAchat = form.querySelector('input[name="is_invest"]');
				let typologyValues = [];
				//as select is multiple, we need to loop through all options
				for (let i = 0; i < typology.options.length; i++) {
					if (typology.options[i].selected) {
						typologyValues.push(typology.options[i].value);
					}
				}
				
				let zone = form.querySelector('input[name="zone"]').value;
				if (zone == '' || zone == 'Dernière recherche') {
					zone = 'Toutes';
				}
				window.dataLayer.push({
					event: 'recherche',
					features: {
						type_achat: typeAchat ? 'Habiter' : 'Investir',
						type_bien: typology,
						zone_geographique: zone,
					},
				});
			}
		});

	// Tracking à chaque recherche avancée
	document.addEventListener(
		'click',
		function (event) {
			const search_button = document.querySelector('.searchform--advanced .searchform__button');
			if (search_button) {
				let form = search_button.closest('form');
				let typeAchat = form.querySelector('input[name="is_invest"]');
				let zone = form.querySelector('input[name="zone"]');
				let typologies = form.querySelectorAll('input[name="typology[]"]');
				let typologyValues = [];
				//as typologies are cheboxes, we need to loop through all options
				typologies.forEach((typology) => {	
					if (typology.checked) {
						typologyValues.push(typology.value);
					}
				});

				let pieces = form.querySelectorAll('input[name="piece_number[]"]');
				let piecesValues = [];
				//as typologies are cheboxes, we need to loop through all options
				pieces.forEach((piece) => {	
					if (piece.checked) {
						piecesValues.push(piece.value);
					}
				});

				let fiscals = form.querySelectorAll('input[name="fiscal[]"]');
				let fiscalsValues = [];
				//as typologies are cheboxes, we need to loop through all options
				fiscals.forEach((fiscal) => {	
					if (fiscal.checked) {
						fiscalsValues.push(fiscal.value);
					}
				});

				let price_min = form.querySelector('input[name="price_min"]');
				if(price_min.value == '') {
					price_min.value = 0;
				}
				let price_max = form.querySelector('input[name="price_max"]');
				console.log(price_max);
				if(price_max.value == '') {
					price_max.value = 0;
				}
				let fourchette_prix = price_min.value + ' - ' + price_max.value;

				let surface_min = form.querySelector('input[name="surface_min"]');
				if(surface_min.value == '') {
					surface_min.value = 0;
				}
				let surface_max = form.querySelector('input[name="surface_max"]');
				if(surface_max.value == '') {
					surface_max.value = 0;
				}
				let fourchette_surface = surface_min.value + ' - ' + surface_max.value;
				
				if (zone == '' || zone == 'Dernière recherche') {
					zone = 'Toutes';
				}
				window.dataLayer.push({
					event: 'recherche',
					features: {
						zone_geographique: zone,
						type_achat: typeAchat ? 'Habiter' : 'Investir',
						type_bien: typology,
						nombre_pieces: piecesValues,
						dispositif_fiscaux: fiscalsValues,
						prix: fourchette_prix,
						surface: fourchette_surface,
					},
				});
			}
		}
	);


	// Tracking à chaque click sur le bouton retour d'une page single programme non terminé
	document.addEventListener(
		'click',
		function (event) {
			const yoast_previous_button = document.querySelector('#breadcrumbs a');
			if (
				yoast_previous_button &&
				yoast_previous_button.textContent === '< Retour'
			) {
				// To do : Mettre à jour l'event
				// window.dataLayer.push({
				//     "event": "breadcrumbs-previous",
				// });
			}
		},
		false
	);

	// Tracking à chaque validation d'une création de compte + newsletter-success
	// -> Voir ajax.js
})();
